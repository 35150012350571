<template>
  <a-modal
    :title="this.model.name"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="() => { $emit('cancel') }"
  >
    <template #footer>
      <a-button key="back" @click="cancel">关闭</a-button>
    </template>
    <a-spin :spinning="loading">
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </s-table>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { goods_inventory_record } from '@/api/c_wms_goods'
import { STable } from '@/components'
// 表单字段
const fields = ['id', 'name', 'code', 'wms_supervised_warehouse_id']
export default {
  components: {
    STable
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      pageNum: 1,
      loading: false,
      form: this.$form.createForm(this),
      columns: [
        {
          title: '序号',
          dataIndex: 'serial',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          align: 'center',
          dataIndex: 'warehouse'
        },
        {
          title: '库位',
          align: 'center',
          dataIndex: 'cargo_space'
        },
        {
          title: '供应商',
          dataIndex: 'supplier'
        },
        {
          title: '批次',
          dataIndex: 'batch'
        },
        {
          title: '数量',
          dataIndex: 'count'
        },
        {
          title: '时间',
          width: 160,
          dataIndex: 'create_time'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_inventory_record(Object.assign(parameter, { barcode: this.model.barcode }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
