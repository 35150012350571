<template>
  <div>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="序号" >
            <a-input-number :min="1" v-decorator="['serial', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            <span style="margin-left: 30px"><span style="color: red">提示：</span>序号小的在前边</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="条形码">
            <a-input disabled v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="货品名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开启保质期" >
            <a-switch v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openSelfLife"/>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="open_self_life">
          <a-form-item label="保质期">
            <a-input-number precision="0" style="width: 70%" type="number" v-decorator="['shelf_life', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            <a-select
              v-decorator="[
                'shelf_life_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              style="width: 30%"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.shelf_life_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="open_self_life && false">
          <a-form-item label="保质期单位">
            <a-select
              v-decorator="[
                'shelf_life_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              style="width: 90px"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.shelf_life_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="备注" >
            <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </a-form-item>
        </a-col>
        <a-col span="6" :offset="6">
          <a-button @click="handleGoBack">返回</a-button>
        </a-col>
        <a-col span="2" style="text-align: center">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { goods_list_id, goods_update } from '@/api/c_wms_goods'
// 表单字段
const fields = ['id', 'name', 'barcode', 'open_self_life',
  'shelf_life', 'shelf_life_unit', 'total_count', 'serial', 'remark'
]
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      id: 0,
      fetching: false,
      goods_type: 1,
      location: '',
      wms_goodss: [],
      categoryList: [],
      open_self_life: false,
      open_consumption_tax: 1,
      tax_rate_info: {
        open_consumption_tax: this.open_consumption_tax,
        consumption_tax_unit: this.consumption_tax_unit,
        tax_level_list: []
      },
      cacheData: [],
      editingKey: '',
      tax_level_columns: [
        {
          title: '最小值',
          dataIndex: 'min_price',
          width: 100,
          scopedSlots: { customRender: 'min_price' }
        },
        {
          title: '最大值',
          dataIndex: 'max_price',
          width: 100,
          scopedSlots: { customRender: 'max_price' }
        },
        {
          title: '比例',
          dataIndex: 'consumption_tax_rate',
          width: 100,
          scopedSlots: { customRender: 'consumption_tax_rate' }
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
      this.openSelfLife(this.record.open_self_life)
    })
  },
  methods: {

    openSelfLife (value) {
      this.open_self_life = !!value
    },
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleCategoryChange (value) {
      if (value.length !== 3) {
        this.$message.warning('所属分类必须三级')
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    goodsTypeChange (value) {
      this.goods_type = Number(value)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          goods_update(values, this.id).then((res) => {
            console.log(res)
            if (res.code === 1000) {
              this.handleGoBack()
            }
          })
        }
      })
    },
    wmsWarehouseList () {
      goods_list_id().then(res => {
        this.wms_goodss = res.data
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        formData.shelf_life_unit = formData.shelf_life_unit + ''
        form.setFieldsValue(formData)
      })
    },
    openConsumptionTax (value) {
      this.open_consumption_tax = value ? 2 : 1
      console.log(this.open_consumption_tax)
    },
    handleAddTaxLevel () {
      this.tax_rate_info.tax_level_list.push({
        key: this.tax_rate_info.tax_level_list.length + 1,
        min_price: 0,
        max_price: 0,
        consumption_tax_rate: 0.00,
        editable: false
      })
      this.cacheData = this.tax_rate_info.tax_level_list
    },
    handleTaxLevelChange (value, key, column) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.tax_rate_info.tax_level_list = newData
      }
    },
    edit (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = key
      if (target) {
        target.editable = true
        this.tax_rate_info.tax_level_list = newData
      }
    },
    save (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const newCacheData = [...this.cacheData]
      const target = newData.find(item => key === item.key)
      const targetCache = newCacheData.find(item => key === item.key)
      if (target && targetCache) {
        delete target.editable
        this.tax_rate_info.tax_level_list = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key))
        delete target.editable
        this.tax_rate_info.tax_level_list = newData
      }
    },
    onDelete (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key))
        delete target.editable
        this.tax_rate_info.tax_level_list = newData.filter(item => {
          if (item.key !== key) {
            return item
          }
        })
      }
    }
  }
}
</script>
